export const formatValue = (value) => {
  if (value >= 1000000000000) {
    return (value / 1000000000000).toFixed(1) + "T";
  } else if (value >= 1000000000) {
    return (value / 1000000000).toFixed(1) + "B";
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(1) + "M";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(1) + "K";
  } else if (value >= 100000) {
    return (value / 100000).toFixed(1) + "L";
  } else {
    return value.toString();
  }
};
