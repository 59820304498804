import React, { useContext, useEffect, useState } from "react";
import bookblue from "../Images/bookblue.png";
import pignose from "../Images/pignose.png";
import logo from "../Images/logo.png";
import circleinfo from "../Images/circleinfo.png";
import Loading from "../components/SmallComponents/loading";
import { useWeb3Modal } from "@web3modal/react";
import {
  StyledButton,
  ToastNotify,
} from "../components/SmallComponents/AppComponents";
import { getAccount } from "@wagmi/core";
import { FaPiggyBank } from "react-icons/fa";
import { FaEthereum } from "react-icons/fa";
import { FaPowerOff } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { FaTelegramPlane } from "react-icons/fa";
import { SiGitbook } from "react-icons/si";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Dialog, Tooltip, Typography, useMediaQuery } from "@mui/material";
import {
  stakeReadFunction,
  stakeWriteFunction,
  tokenReadFunction,
  tokenWriteFunction,
} from "../ConnectivityAssets/hooks";
import { fetchBalance } from "@wagmi/core";
import { AppContext } from "../utils";
import { formatUnits, parseUnits } from "viem";
import { stakeAddress } from "../ConnectivityAssets/environment";
import { formatValue } from "./formatValue";

function HeroSection() {
  const matches = useMediaQuery("(max-width:950px)");
  const { open } = useWeb3Modal();
  const { address } = getAccount();
  const { account } = useContext(AppContext);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState(0.0);
  const [pigbalance, setPigBalance] = useState(0.0);
  const [amountforCard2, setAmountforCard2] = useState("");
  const [amountforCard3, setAmountforCard3] = useState("");
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalStakedCard2, setTotalStakedCard2] = useState(0);
  const [totalStakedCard3, setTotalStakedCard3] = useState(0);
  const [userStakeCard1, setUserStakeCard1] = useState(0);
  const [userStakeCard2, setUserStakeCard2] = useState(0);
  const [userStakeCard3, setUserStakeCard3] = useState(0);
  const [unClaimedPigCard1, setUnClaimedPigCard1] = useState(0);
  const [unClaimedPigCard2, setUnClaimedPigCard2] = useState(0);
  const [unClaimedPigCard3, setUnClaimedPigCard3] = useState(0);
  const [callFunction, setCallFunction] = useState(false);
  const [existplan1, setExistplan1] = useState(false);
  const [existplan2, setExistplan2] = useState(false);
  const [existplan3, setExistplan3] = useState(false);
  const [userStakeInfo, setUserStakeInfo] = useState([]);
  const [userStakeInfocard2, setUserStakeInfocard2] = useState([]);
  const [userStakeInfocard3, setUserStakeInfocard3] = useState([]);
  const [openbox, setOpenbox] = useState(false);
  const [openbox1, setOpenbox1] = useState(false);
  const [openbox2, setOpenbox2] = useState(false);
  const [withdrawPlanIndex, setWithdrawPlanIndex] = useState("");
  const [withdrawIndex, setWithdrawIndex] = useState("");
  const [withdrawExist, setWithdrawExist] = useState(false);

  const [openWithdraw, setOpenWithdraw] = useState(false);
  const [loading, setloading] = useState(false);

  const handleClose = () => {
    setOpenbox(false);
  };
  const handleOpen = () => {
    setOpenbox(true);
  };
  const handleClose1 = () => {
    setOpenbox1(false);
  };
  const handleOpen1 = () => {
    setOpenbox1(true);
  };
  const handleClose2 = () => {
    setOpenbox2(false);
  };
  const handleOpen2 = () => {
    setOpenbox2(true);
  };
  const handleCloseWithdraw = () => {
    setOpenWithdraw(false);
  };
  const handleOpenWithdraw = (index, stakeId, isWithdrawn) => {
    setWithdrawPlanIndex(index);
    setWithdrawIndex(stakeId);
    setWithdrawExist(isWithdrawn);
    setOpenWithdraw(true);
  };
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const cardsData = [
    {
      duration: "30 DAY LOCK",
      percentage: "300%",
      tvl: totalStaked,
      UNCLAIMEDPIGS: parseFloat(unClaimedPigCard1).toFixed(5),
      value: amount,
      setValue: setAmount,
      userStake: userStakeCard1,
      dPeriod: "30 DAYS",
      isExists: existplan1,
      openbox: openbox,
      handleClose: handleClose,
      handleOpen: handleOpen,
      stakeDataArray: userStakeInfo,
    },
    {
      duration: "60 DAY LOCK",
      percentage: "1000%",
      tvl: totalStakedCard2,
      UNCLAIMEDPIGS: parseFloat(unClaimedPigCard2).toFixed(5),
      value: amountforCard2,
      setValue: setAmountforCard2,
      userStake: userStakeCard2,
      dPeriod: "60 DAYS",
      isExists: existplan2,
      openbox: openbox1,
      handleClose: handleClose1,
      handleOpen: handleOpen1,
      stakeDataArray: userStakeInfocard2,
    },
    {
      duration: "90 DAY LOCK",
      percentage: "10,000%",
      tvl: totalStakedCard3,
      UNCLAIMEDPIGS: parseFloat(unClaimedPigCard3).toFixed(5),
      value: amountforCard3,
      setValue: setAmountforCard3,
      userStake: userStakeCard3,
      dPeriod: "90 DAYS",
      isExists: existplan3,
      openbox: openbox2,
      handleClose: handleClose2,
      handleOpen: handleOpen2,
      stakeDataArray: userStakeInfocard3,
    },
  ];
  useEffect(() => {
    (async () => {
      try {
        for (let index = 0; index < 3; index++) {
          let totalstaked = await stakeReadFunction("getPoolInfo", [index]);
          console.log(
            +formatUnits(totalstaked[3].toString(), 18),
            "totalstaked"
          );
          if (index === 0) {
            setTotalStaked(+formatUnits(totalstaked[3].toString(), 18));
          } else if (index === 1) {
            setTotalStakedCard2(+formatUnits(totalstaked[3].toString(), 18));
          } else {
            setTotalStakedCard3(+formatUnits(totalstaked[3].toString(), 18));
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction]);
  useEffect(() => {
    (async () => {
      try {
        const ethBal = await fetchBalance({
          address: account,
        });
        const balance = ethBal.formatted;
        console.log(balance, "balance");
        setBalance(balance);
        let tokenBalance = await tokenReadFunction("balanceOf", [account]);
        setPigBalance(+formatUnits(tokenBalance.toString(), 18));
        //////user data///////////////////
        let getUserDataPlan1 = await stakeReadFunction("getUserInfo", [
          account,
          0,
        ]);
        let getUserDataPlan2 = await stakeReadFunction("getUserInfo", [
          account,
          1,
        ]);
        let getUserDataPlan3 = await stakeReadFunction("getUserInfo", [
          account,
          2,
        ]);
        console.log(
          +formatUnits(getUserDataPlan1[2].toString(), 18),
          "getUserDataPlan1 staked",
          getUserDataPlan1[0],
          "stakecount1",
          getUserDataPlan1[1].toString()
        );
        console.log(
          +formatUnits(getUserDataPlan2[2].toString(), 18),
          "getUserDataPlan2 staked",
          getUserDataPlan2[0],
          "stakecount2",
          getUserDataPlan2[1].toString()
        );
        console.log(
          +formatUnits(getUserDataPlan3[2].toString(), 18),
          "getUserDataPlan3 staked",
          getUserDataPlan3[0],
          "stakecount3",
          getUserDataPlan3[1].toString()
        );
        setExistplan1(getUserDataPlan1[0]);
        setExistplan2(getUserDataPlan2[0]);
        setExistplan3(getUserDataPlan3[0]);
        setUserStakeCard1(+formatUnits(getUserDataPlan1[2].toString(), 18));
        setUserStakeCard2(+formatUnits(getUserDataPlan2[2].toString(), 18));
        setUserStakeCard3(+formatUnits(getUserDataPlan3[2].toString(), 18));
        let unClaimedRewardPlan1 = 0;
        let unClaimedRewardPlan2 = 0;
        let unClaimedRewardPlan3 = 0;

        for (let index = 0; index < getUserDataPlan1[1].toString(); index++) {
          let unclaimedTokensPlan1 = await stakeReadFunction(
            "calculateReward",
            [account, 0, index]
          );
          unClaimedRewardPlan1 =
            unClaimedRewardPlan1 +
            +formatUnits(unclaimedTokensPlan1[0].toString(), 18);
        }
        console.log(unClaimedRewardPlan1, "unClaimedRewardPlan1");
        for (let index = 0; index < getUserDataPlan2[1].toString(); index++) {
          let unclaimedTokensPlan2 = await stakeReadFunction(
            "calculateReward",
            [account, 1, index]
          );
          unClaimedRewardPlan2 =
            unClaimedRewardPlan2 +
            +formatUnits(unclaimedTokensPlan2[0].toString(), 18);
        }

        for (let index = 0; index < getUserDataPlan3[1].toString(); index++) {
          let unclaimedTokensPlan3 = await stakeReadFunction(
            "calculateReward",
            [account, 2, index]
          );
          unClaimedRewardPlan3 =
            unClaimedRewardPlan3 +
            +formatUnits(unclaimedTokensPlan3[0].toString(), 18);
        }
        console.log(
          unClaimedRewardPlan1,
          "unClaimedRewardPlan1",
          unClaimedRewardPlan2,
          unClaimedRewardPlan3
        );
        setUnClaimedPigCard1(unClaimedRewardPlan1);
        setUnClaimedPigCard2(unClaimedRewardPlan2);
        setUnClaimedPigCard3(unClaimedRewardPlan3);
        ///////user data for card 1//////////////////////
        let stakeDetail = await stakeReadFunction("getUserInfo", [account, 0]);
        console.log(+stakeDetail[1].toString(), "stakeCount");
        let stakeCount = +stakeDetail[1].toString();
        let arr = [];
        for (let index = 0; index < +stakeCount; index++) {
          let userStakeData = await stakeReadFunction("getUserStakeInfo", [
            account,
            0,
            index.toString(),
          ]);
          const data = {
            stakeId: +index.toString(),
            token: +formatUnits(userStakeData[0].toString(), 18),
            claimedRewad: +formatUnits(userStakeData[3].toString(), 18),
            isWithdrawn: userStakeData[6],
          };
          arr.push(data);
        }
        setUserStakeInfo([...arr]);
        ///////user data for card 2//////////////////////
        let stakeDetail2 = await stakeReadFunction("getUserInfo", [account, 1]);
        console.log(+stakeDetail2[1].toString(), "stakeCount");
        let stakeCountcard2 = +stakeDetail2[1].toString();
        let arr1 = [];
        for (let index = 0; index < +stakeCountcard2; index++) {
          let userStakeData = await stakeReadFunction("getUserStakeInfo", [
            account,
            1,
            index.toString(),
          ]);
          const data = {
            stakeId: +index.toString(),
            token: +formatUnits(userStakeData[0].toString(), 18),
            claimedRewad: +formatUnits(userStakeData[3].toString(), 18),
            isWithdrawn: userStakeData[6],
          };
          arr1.push(data);
        }
        setUserStakeInfocard2([...arr1]);
        ///////user data for card 2//////////////////////
        let stakeDetail3 = await stakeReadFunction("getUserInfo", [account, 1]);
        console.log(+stakeDetail3[1].toString(), "stakeCount");
        let stakeCountcard3 = +stakeDetail3[1].toString();
        let arr2 = [];
        for (let index = 0; index < +stakeCountcard3; index++) {
          let userStakeData = await stakeReadFunction("getUserStakeInfo", [
            account,
            2,
            index.toString(),
          ]);
          const data = {
            stakeId: +index.toString(),
            token: +formatUnits(userStakeData[0].toString(), 18),
            claimedRewad: +formatUnits(userStakeData[3].toString(), 18),
            isWithdrawn: userStakeData[6],
          };
          arr2.push(data);
        }
        setUserStakeInfocard3([...arr2]);
        setCallFunction(false);
      } catch (error) {
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callFunction, account]);

  ////////////////////////////////////
  const stakeHandler = async (index) => {
    try {
      console.log(index, "index");
      if (!account) {
        return showAlert("Please connect your wallet");
      }

      if (
        (index === 0 && !amount) ||
        (index === 1 && !amountforCard2) ||
        (index === 2 && !amountforCard3)
      ) {
        return showAlert("Please enter the amount");
      }

      const tokenDecimals = await tokenReadFunction("decimals");

      const checkAllowance = await tokenReadFunction("allowance", [
        account,
        stakeAddress,
      ]);
      const checkTotalAllowance = +formatUnits(
        checkAllowance.toString(),
        tokenDecimals
      );
      setloading(true);
      if (checkTotalAllowance < amount) {
        await tokenWriteFunction("approve", [
          stakeAddress,
          "999999999999999999999999999999",
        ]);
      }

      await stakeWriteFunction("stake", [
        index === 0
          ? parseUnits(amount.toString(), tokenDecimals)
          : index === 1
          ? parseUnits(amountforCard2.toString(), tokenDecimals)
          : parseUnits(amountforCard3.toString(), tokenDecimals),
        index.toString(),
      ]);
      setCallFunction(true);
      setloading(false);
      setAlertState({
        open: true,
        message: "Transaction confirmed!!!",
        severity: "success",
      });
    } catch (error) {
      setloading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  };
  const claimHandler = async (index) => {
    try {
      console.log(index, "index");

      if (!account) {
        return showAlert("Please connect your wallet");
      }
      setloading(true);
      await stakeWriteFunction("claimAll", [index.toString()]);
      setloading(false);

      setAlertState({
        open: true,
        message: "claimed successfully!!!",
        severity: "success",
      });
    } catch (error) {
      setloading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  };
  ////////////////////////withdraw handler/////////////////////////
  const withdrawHandler = async () => {
    console.log(withdrawPlanIndex, withdrawIndex, "indexs");
    try {
      if (!account) {
        return showAlert("Please connect your wallet");
      }
      setloading(true);
      await stakeWriteFunction("withdraw", [
        withdrawPlanIndex.toString(),
        withdrawIndex.toString(),
      ]);
      setloading(false);

      setAlertState({
        open: true,
        message: "withdraw successfully!!!",
        severity: "success",
      });
    } catch (error) {
      setloading(false);
      const errorMessage = error?.shortMessage;
      showAlert(errorMessage);
    }
  };
  return (
    <>
      <Loading loading={loading} />
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Dialog
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "300px", // Set your width here
              background: "#09042C",
              borderRadius: "25px",
              // border: "2px solid #fff",
              p: 2,
            },
          },
        }}
        open={openWithdraw}
        onClose={handleCloseWithdraw}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box display="flex" justifyContent="flex-end">
          <CloseIcon
            style={{
              color: "#fff",
              cursor: "pointer",
            }}
            onClick={handleCloseWithdraw}
          />
        </Box>
        <Typography
          mt={1}
          sx={{
            color: "#DF9590",
            fontSize: "18px",
            fontFamily: "Poppins",
            textAlign: "center",
            fontWeight: "400",
          }}
        >
          {withdrawExist
            ? "You have already withdraw tokens"
            : "Staked tokens are ready to withdraw"}
        </Typography>
        {!withdrawExist ? (
          <Box display="flex" justifyContent="center" mt={3}>
            <Box
              onClick={withdrawHandler}
              sx={{
                background: "#5200C9",
                borderRadius: "25px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "150px",
                height: "40px",
                color: "#fff",
                cursor: "pointer",
              }}
            >
              Withdraw
            </Box>
          </Box>
        ) : null}
      </Dialog>
      <div className="main__wrapper">
        <a
          href="https://moonpigs.gitbook.io"
          target="_blank"
          className="pig__book"
          style={{ textDecoration: "none" }}
        >
          <span>
            <img src={bookblue} alt="book" />
          </span>{" "}
          pigbook
        </a>
        <a href="#" className="logo__button">
          <img src={logo} alt="logo" />
          <span>
            BUY PIGS <br />
            EARN PIGS
          </span>
        </a>
        <h1>MOONPIGS</h1>
        {address ? (
          <div
            // className="info__wrapper"
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: matches && "20px",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: matches ? "17px" : "30px",
              }}
            >
              <span>
                <FaPiggyBank style={{ fontSize: "30px", color: "#DF9590" }} />
              </span>
              <p
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontSize: matches ? "14px" : "",
                  fontWeight: "700",
                }}
              >
                {address.slice(0, 4) + "..." + address.slice(-4)}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: matches ? "17px" : "30px",
              }}
            >
              <span>
                <FaEthereum style={{ fontSize: "30px", color: "#DF9590" }} />
              </span>
              <p
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontSize: matches ? "14px" : "",
                  fontWeight: "700",
                }}
              >
                {parseFloat(balance).toFixed(4)} ETH
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginRight: matches ? "17px" : "30px",
              }}
            >
              <span>
                <img
                  src={pignose}
                  width="30px"
                  style={{ marginTop: "7px" }}
                  alt="pig"
                />
              </span>
              <p
                style={{
                  marginTop: matches ? "15px" : "17px",
                  color: "#fff",
                  fontSize: matches ? "14px" : "",
                  fontFamily: "Poppins",
                  fontWeight: "700",
                }}
              >
                {formatValue(parseFloat(pigbalance).toFixed(0))} PIGS
              </p>
            </div>

            <FaPowerOff
              onClick={async () => {
                await open();
              }}
              style={{ fontSize: "30px", color: "#DF9590", cursor: "pointer" }}
            />
          </div>
        ) : (
          <Box mb={6}>
            <StyledButton
              width="280px"
              onClick={async () => {
                await open();
              }}
            >
              CONNECT YOUR WALLET
            </StyledButton>
          </Box>
        )}

        <div className="lock__info">
          {cardsData.map(
            (
              {
                duration,
                percentage,
                tvl,
                UNCLAIMEDPIGS,
                value,
                setValue,
                userStake,
                dPeriod,
                isExists,
                openbox,
                handleClose,
                handleOpen,
                stakeDataArray,
              },
              index
            ) => {
              return (
                <>
                  <Dialog
                    sx={{
                      "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                          width: "100%",
                          maxWidth: "500px", // Set your width here
                          background: "#09042C",
                          borderRadius: "10px",
                          border: "2px solid #fff",
                          p: 2,
                        },
                      },
                    }}
                    open={openbox}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <Typography
                      mt={2}
                      sx={{
                        color: "#DF9590",
                        fontSize: "33px",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        fontWeight: "700",
                      }}
                    >
                      {duration}
                    </Typography>
                    <Typography
                      mt={2}
                      sx={{
                        color: "#fff",
                        fontSize: "18px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Enter Amount for Stake
                    </Typography>
                    <Box
                      my={1}
                      sx={{
                        background: "#fff",
                        borderRadius: "16px",
                        width: "100%",
                        height: "50px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <input
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                        type="text"
                        placeholder="Enter amount"
                        style={{
                          textAlign: "left",
                          width: "100%",
                          border: "none",
                          background: "none",
                          color: "#000",
                          fontSize: "16px",
                          fontWeight: "700",
                          outline: "none",
                          fontFamily: "Poppins",
                          paddingLeft: "10px",
                        }}
                      />
                    </Box>
                    <Box display="flex" justifyContent="center" mt={3}>
                      <Box
                        onClick={() => stakeHandler(index)}
                        sx={{
                          background: "#5200C9",
                          borderRadius: "25px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "150px",
                          height: "40px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      >
                        Stake
                      </Box>
                    </Box>
                  </Dialog>
                  <div className="elem__lock" key={index}>
                    <div className="top__part">
                      <span>{duration}</span>
                      <h6>
                        {percentage} <br /> <span>APY</span>
                      </h6>
                      <div className="tvl">
                        <p>
                          <span>TVL</span>
                          {formatValue(parseFloat(tvl).toFixed(0))} $PIGS
                        </p>
                      </div>
                      <div className="double__info">
                        <div className="el__info">
                          <span>
                            {formatValue(parseFloat(userStake).toFixed(0))}
                          </span>
                          <p>Your pigs</p>
                        </div>
                        <div className="el__info">
                          <span>
                            {formatValue(parseFloat(UNCLAIMEDPIGS).toFixed(0))}
                          </span>
                          <p>Unclaimed pigs</p>
                        </div>
                      </div>
                      <div className="controls">
                        <a onClick={handleOpen} className="deposit">
                          STAKE
                        </a>
                        <a
                          onClick={() => claimHandler(index)}
                          className="claim"
                        >
                          CLAIM
                        </a>
                      </div>
                    </div>
                    {isExists ? (
                      <div className="history__wrapper">
                        <div className="history__table">
                          <table>
                            <tr>
                              <th>PIGS</th>
                              <th>ACTION</th>
                              <th>DURATION</th>
                              <th></th>
                            </tr>
                            {stakeDataArray.map(
                              (
                                { token, claimedRewad, stakeId, isWithdrawn },
                                i
                              ) => {
                                return (
                                  <>
                                    <tr>
                                      <td>
                                        <p>
                                          {formatValue(
                                            parseFloat(token).toFixed(0)
                                          )}
                                        </p>
                                      </td>
                                      <td>
                                        <p>STAKED</p>
                                      </td>
                                      <td>
                                        <p>{dPeriod}</p>
                                      </td>
                                      <td>
                                        <img
                                          onClick={() =>
                                            handleOpenWithdraw(
                                              index,
                                              stakeId,
                                              isWithdrawn
                                            )
                                          }
                                          src={circleinfo}
                                          alt="circleinfo"
                                          style={{ cursor: "pointer" }}
                                        />
                                      </td>
                                    </tr>
                                    {claimedRewad > 0 ? (
                                      <tr>
                                        <td>
                                          <p>
                                            {formatValue(
                                              parseFloat(claimedRewad).toFixed(
                                                0
                                              )
                                            )}
                                          </p>
                                        </td>
                                        <td>
                                          <p>CLAIMED</p>
                                        </td>
                                        <td>
                                          <p>{dPeriod}</p>
                                        </td>
                                        <td>
                                          <Tooltip title="You have successfully claimed">
                                            <img
                                              src={circleinfo}
                                              alt="circleinfo"
                                              style={{ cursor: "pointer" }}
                                            />
                                          </Tooltip>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </>
                                );
                              }
                            )}
                          </table>
                        </div>
                      </div>
                    ) : (
                      <div className="history__wrapper">
                        <div className="empty__history">
                          <p>no history</p>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              );
            }
          )}
        </div>
        <h6>Up to 10,000% APY</h6>
        <div className="socials">
          <ul>
            <li>
              <a
                href="https://x.com/APYMoonPigs"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <BsTwitter style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </li>
            <li>
              <a
                href="https://t.me/APYMoonPigs "
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <FaTelegramPlane style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </li>
            <li>
              <a
                href="https://moonpigs.gitbook.io"
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <SiGitbook style={{ fontSize: "30px", color: "#fff" }} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
